<template>
  <div class="promo-new">
    <div class="font-weight-bold mb-3">Create new promo code</div>
    <v-card outlined min-height="70vh" color="d-flex flex-column">
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="font-weight-bold mb-3">Promo code</div>
        <v-text-field
          class="field46"
          v-model="data.code"
          placeholder="Enter code"
          :error-messages="codeErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="font-weight-bold mb-3">Code group</div>
        <v-select
          class="field46"
          v-model="data.group"
          placeholder="Select"
          :error-messages="groupErrors"
          :items="itemsGroup"
          item-text="name"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="d-flex align-center v-input mt-n3 mb-3">
          <div class="text-caption gray--text">You can create new group for refill codes</div>
          <v-spacer></v-spacer>
          <v-btn @click="modal = true" text color="primary lighten-1" x-small><span class="text-caption">+ Add new</span></v-btn>
        </div>
        <div class="font-weight-bold mb-3">Usage limit</div>
        <v-text-field
          class="field46"
          v-model="data.usage_limit"
          placeholder="Enter limit"
          :error-messages="limitErrors"
          type="number"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="v-input mt-n3 mb-3">
          <div class="text-caption gray--text">0 for unlimited usage</div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="createItem" depressed class="rounded primary text-body-2" height="30" width="106">Create</v-btn>
        <v-btn
          :to="`/promotions/${$route.params.promotionId}/promo-codes`"
          depressed
          class="ms-3 rounded"
          outlined
          width="106"
          height="30"
          text
          color="gray lighten-1"
        >
          <span class="black--text text-body-2">Cancel</span>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-h6 font-weight-bold mb-5">Add new group</div>
        <v-text-field
          class="field46"
          v-model="title"
          placeholder="Enter title"
          :error-messages="titleErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="text-end mt-2">
          <v-btn @click="createGroup" depressed class="primary rounded text-body-2" width="106" height="30">Create</v-btn>
          <v-btn @click="modal = false" depressed class="ms-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      data: {},
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      title: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getPromotionGroupList');
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Promo code was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async createGroup() {
      this.error = [];
      await this.$store
        .dispatch('createPromotionGroup', { name: this.title })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
    async createItem() {
      this.error = [];
      await this.$store
        .dispatch('createPromotionCode', this.data)
        .then(() => {
          this.notifi('done');
          this.$router.push(`/promotions/${this.$route.params.promotionId}/promo-codes`);
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  watch: {
    modal() {
      this.title = '';
      this.error = [];
    },
  },
  computed: {
    itemsGroup() {
      return this.$store.getters.promotionGroupList;
    },
    codeErrors() {
      const errors = [];
      this.error.find((item) => item == 'code__required') && errors.push('Please enter code');
      this.error.find((item) => item == 'code__invalid') && errors.push('Provided code is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    limitErrors() {
      const errors = [];
      this.error.find((item) => item == 'usage_limit__required') && errors.push('Please enter usage limit');
      this.error.find((item) => item == 'usage_limit__invalid') && errors.push('Provided usage limit is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    groupErrors() {
      const errors = [];
      this.error.find((item) => item == 'group__required') && errors.push('Please select group');
      this.error.find((item) => item == 'group__invalid') && errors.push('Selected group is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'name__required') && errors.push('Please enter name');
      this.error.find((item) => item == 'name__invalid') && errors.push('Provided name is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setPromotionGroupList');
  },
};
</script>

<style lang="scss">
#app .promo-new {
  .v-input {
    max-width: 371px;
  }
}
</style>
